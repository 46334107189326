<template>
  <div>
    <h2>Batches</h2>
    <DiskBatches />
  </div>
</template>

<script>
import DiskBatches from '@/components/DiskBatches/DiskBatches.vue'

export default {
  components: {
    DiskBatches,
  },
}
</script>
